
import { defineComponent } from "vue";
import { IonApp, IonMenu, IonHeader, IonToolbar, IonContent, IonMenuToggle, IonLabel, IonItem } from "@ionic/vue";

export default defineComponent({
  name: "GuestApp",
  components: {
    IonApp,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonContent,
    IonMenuToggle,
    IonLabel,
    IonItem,
  },
});
